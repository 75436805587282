import React from 'react'
import './App.css'
import { CheckShieldFill, StarFill } from 'antd-mobile-icons'

const App = () => {
  return (
    <div className="app">
      <div className="body">
        <p>
          <CheckShieldFill color="green" />
          渠道单元名称：浙江温州德之和网厅代理店
        </p>
        <p>
          <CheckShieldFill color="green" />
          渠道单元编码：3303002416560
        </p>
        <p>
          <CheckShieldFill color="green" />
          渠道单元地址：江苏省常州市武进区科教城创研港3C801
        </p>

        <p>
          <StarFill color="blue" />
          该店为中国电信授权代理，请您放心购买！
        </p>
      </div>
    </div>
  )
}

export default App
